<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-2"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="BookIcon"
              size="18"
            />
            <span class="align-middle ml-50">{{ $route.params.serviceType.toUpperCase() }}</span>
          </b-card-title>
        </b-card-header>
        <validation-observer ref="formData">
          <b-form class="p-2">
            <b-row>
              <b-col cols="6">
                <b-form-group
                  :label="$t('Image')"
                  label-for="register-image"
                >
                  <!-- media -->
                  <b-media no-body>
                    <b-media-aside>
                      <b-link>
                        <b-img
                          v-if="image"
                          ref="previewEl"
                          rounded
                          :src="image"
                          height="80"
                        />
                        <b-img
                          v-else
                          ref="previewEl"
                          :src="require('@/assets/images/blank/no_image.png')"
                          rounded
                          height="80"
                        />
                      </b-link>
                    <!--/ avatar -->
                    </b-media-aside>

                    <b-media-body class="mt-75 ml-75">
                      <!-- upload button -->
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="$refs.refInputEl.$el.click()"
                      >
                        {{ $t("Upload") }}
                      </b-button>
                      <b-form-file
                        ref="refInputEl"
                        accept=".webp, .jpeg, .jpg, .png, .gif"
                        :hidden="true"
                        plain
                        @change="inputImageRenderer"
                      />
                      <!--/ upload button -->

                      <!-- reset -->
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        size="sm"
                        class="mb-75 mr-75"
                        @click="resetImage"
                      >
                        {{ $t("Reset") }}
                      </b-button>
                      <!--/ reset -->
                      <b-card-text>{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG. {{ $t('Max size of') }} 300KB</b-card-text>
                    </b-media-body>
                  </b-media>
                <!--/ media -->
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <span>{{ $t('Color') }}</span>
                <b-row>
                  <b-col cols="6">
                    <b-form-input
                      id="input-lg-color"
                      v-model="color"
                      placeholder="Color"
                      type="color"
                    />
                  </b-col>
                  <b-col cols="6">
                    <b-form-input
                      id="input-lg-color"
                      v-model="color"
                      placeholder="Color"
                    />
                  </b-col>

                </b-row>

              </b-col>
              <b-col cols="6">
                <!-- refId -->
                <b-form-group
                  :label="$t('Name')"
                  label-for="register-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    vid="name"
                    rules="required"
                  >
                    <b-form-input
                      id="register-name"
                      v-model="name"
                      name="register-name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group
                  :label="$t('System Options')"
                  label-for="register-systemOptions"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="systemOptions"
                    vid="systemOptions"
                    rules="required"
                  >
                    <v-select
                      id="register-systemOptions"
                      v-model="systemOptions"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="OptionSystemOptions"
                      :reduce="(OptionSystemOptions) => OptionSystemOptions.value"
                      class="select-size-lg"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider></b-form-group>
              </b-col>
              <b-col cols="6">
                <!-- refId -->
                <b-form-group
                  :label="$t('Detail')"
                  label-for="register-detail"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Detail"
                    vid="detail"
                    rules="required"
                  >
                    <b-form-input
                      id="register-detail"
                      v-model="detail"
                      name="register-detail"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Detail')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="$t('Service Area')"
                  label-for="register-serviceArea"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="serviceArea"
                    vid="serviceArea"
                    rules=""
                  >
                    <v-select
                      id="register-serviceArea"
                      v-model="serviceArea"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="serviceAreaData"
                      :reduce="(serviceAreaData) => serviceAreaData._id"
                      class="select-size-lg"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider></b-form-group>
              </b-col>
              <b-col cols="12">
                <!-- refId -->
                <b-form-group
                  :label="$t('Main Detail')"
                  label-for="register-mainDetail"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Main Detail"
                    vid="mainDetail"
                    rules="required"
                  >
                    <!-- <b-form-input
                      id="register-mainDetail"
                      v-model="mainDetail"
                      name="register-mainDetail"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Main Detail')"
                    /> -->
                    <b-form-textarea
                      id="register-mainDetail"
                      v-model="mainDetail"
                      name="register-mainDetail"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Main Detail')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="payForThePackage === 'package'"
                cols="6"
              >
                <!-- refId -->
                <b-form-group
                  :label="$t('Limited Time')+' ('+$t('Day')+')'"
                  label-for="register-limitedTime"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="LimitedTime"
                    vid="limitedTime"
                    rules="required"
                  >
                    <b-form-input
                      id="register-limitedTime"
                      v-model="limitedTime"
                      name="register-limitedTime"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Limited Time')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="payForThePackage === 'package'"
                cols="6"
              >
                <!-- refId -->
                <b-form-group
                  :label="$t('Free Tial Time')+' ('+$t('Day')+')'"
                  label-for="register-limitedTime"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="FreeTialTime"
                    vid="freeTialTime"
                    rules="required"
                  >
                    <b-form-input
                      id="register-freeTialTime"
                      v-model="freeTialTime"
                      name="register-freeTialTime"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Free Tial Time')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="payForThePackage === 'package'"
                cols="6"
              >
                <!-- refId -->
                <b-form-group
                  :label="$t('Limited Services')+' ('+$t('Service')+')'"
                  label-for="register-limitedServices"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="LimitedServices"
                    vid="limitedServices"
                    rules="required"
                  >
                    <b-form-input
                      id="register-limitedServices"
                      v-model="limitedServices"
                      name="register-limitedServices"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Limited Services')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                :cols="payForThePackage === 'package' ?'6':'12'"
              >
                <b-form-group
                  :label="$t('Pay For The Package')"
                  label-for="register-payForThePackage"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="payForThePackage"
                    vid="payForThePackage"
                    rules=""
                  >
                    <v-select
                      id="register-payForThePackage"
                      v-model="payForThePackage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="OptionsPayForThePackage"
                      :reduce="(OptionsPayForThePackage) => OptionsPayForThePackage.value"
                      class="select-size-lg"
                      :clearable="false"
                      :disabled="dataId !== null ? true : false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider></b-form-group>
              </b-col>
              <b-col cols="6">
                <!-- refId -->
                <b-form-group
                  :label="$t('Amount')"
                  label-for="register-amount"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="amount"
                    vid="amount"
                    rules="required"
                  >
                    <b-form-input
                      v-if="$route.params.serviceType.split('-')[0] === 'store'"
                      id="register-amount"
                      v-model="storeSetting.amount"
                      name="register-amount"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Amount')"
                    />
                    <b-form-input
                      v-if="$route.params.serviceType.split('-')[0] === 'driver'"
                      id="register-amount"
                      v-model="driverSetting.amount"
                      name="register-amount"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Amount')"
                    />
                    <b-form-input
                      v-if="$route.params.serviceType.split('-')[0] === 'services'"
                      id="register-amount"
                      v-model="serviceSetting.amount"
                      name="register-amount"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Amount')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <!-- refId -->
                <b-form-group
                  :label="$t('Option')"
                  label-for="register-option"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="option"
                    vid="option"
                    rules="required"
                  >
                    <v-select
                      v-if="$route.params.serviceType.split('-')[0] === 'store'"
                      id="register-option"
                      v-model="storeSetting.option"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="Options"
                      :reduce="(Options) => Options.value"
                      class="select-size-lg"
                    />
                    <v-select
                      v-if="$route.params.serviceType.split('-')[0] === 'driver'"
                      id="register-option"
                      v-model="driverSetting.option"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="Options"
                      :reduce="(Options) => Options.value"
                      class="select-size-lg"
                    />
                    <v-select
                      v-if="$route.params.serviceType.split('-')[0] === 'services'"
                      id="register-option"
                      v-model="serviceSetting.option"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="Options"
                      :reduce="(Options) => Options.value"
                      class="select-size-lg"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="$route.params.serviceType.split('-')[0] === 'driver'"
                cols="12"
              >
                <!-- refId -->
                <b-form-group
                  :label="$t('Delivery Table')"
                  label-for="register-deliveryTable"
                >
                  <b-table
                    striped
                    responsive
                    class="mb-0"
                    :items="driverSetting.deliveryTable"
                    :fields="tableColumns"
                    style="height: 60vh;"
                  >
                    <template #cell(start)="data">
                      <b-form-input
                        :id="'start'+data.index"
                        v-model="driverSetting.deliveryTable[data.index].start"
                        name="start"
                        :placeholder="$t('Start')"
                      />
                    </template>
                    <template #cell(end)="data">
                      <b-form-input
                        :id="'end'+data.index"
                        v-model="driverSetting.deliveryTable[data.index].end"
                        name="end"
                        :placeholder="$t('End')"
                      />
                    </template>
                    <template #cell(price)="data">
                      <b-form-input
                        :id="'price'+data.index"
                        v-model="driverSetting.deliveryTable[data.index].price"
                        name="price"
                        :placeholder="$t('Price')"
                      />
                    </template>
                    <template #cell(action)="data">
                      <b-button
                        v-if="data.index === driverSetting.deliveryTable.length-1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="d-flex"
                        @click="addDeliveryTable()"
                      >
                        <feather-icon
                          icon="PlusIcon"
                        />
                      </b-button>
                      <b-button
                        v-if="data.index !== driverSetting.deliveryTable.length-1"

                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="d-flex"
                        @click="deleteDeliveryTable(data.index)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                        />
                      </b-button>
                    </template>
                  </b-table>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <!-- refId -->
                <b-form-group
                  :label="$t('System In Services Table')"
                  label-for="register-deliveryTable"
                >
                  <b-table
                    striped
                    class="mb-0"
                    :items="systemInPackages"
                    :fields="tableSystemInServices"
                    style="height: 60vh;"
                  >
                    <template #cell(systemId)="data">
                      <v-select
                        id="register-option"
                        v-model="systemInPackages[data.index].systemInServicesId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="systemInServicesData"
                        :reduce="(systemInServicesData) => systemInServicesData._id"
                        class="select-size-lg"
                      />
                    </template>
                    <template #cell(status)="data">
                      <b-form-checkbox
                        :checked="
                          systemInPackages[data.index].status
                        "
                        class="custom-control-primary"
                        name="check-button"
                        switch
                        @change="switchChangSystemInServices(data.index, $event)"
                      />
                    </template>
                    <template #cell(action)="data">
                      <b-button
                        v-if="data.index === systemInPackages.length-1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="d-flex"
                        @click="addSystemInServicesTable()"
                      >
                        <feather-icon
                          icon="PlusIcon"
                        />
                      </b-button>
                      <b-button
                        v-if="data.index !== systemInPackages.length-1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="d-flex"
                        @click="deleteSystemInServicesTable(data.index)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                        />
                      </b-button>
                    </template>
                  </b-table>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Action button -->
            <div class="d-flex mt-2">
              <b-button
                variant="primary"
                class="mr-2"
                @click="validationForm()"
              >
                {{ $t("Submit") }}
              </b-button>
              <b-button
                type="button"
                variant="outline-secondary"
                @click="cancel"
              >
                {{ $t("Cancel") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCardHeader,
  BCardTitle,
  BCard,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
  BForm,
  BFormFile,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BOverlay,
  BTable,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import storeModule from '@/store/services/hero/servicePackage'
import storeModuleServiceArea from '@/store/services/hero/serviceArea'

const STORE_MODULE_NAME = 'servicePackage'
const STORE_MODULE_NAME_SERVICE_AREA = 'serviceArea'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardHeader,
    BCardTitle,
    BCard,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    BForm,
    BFormFile,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BButton,
    BOverlay,
    BTable,
    vSelect,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      dataId: null,
      repeated: true,
      limitedTime: '',
      freeTialTime: '',
      limitedServices: '',
      payForThePackage: 'commission',
      image: '',
      color: '#000000',
      name: '',
      detail: '',
      mainDetail: '',
      serviceArea: '',
      systemOptions: 'join_as_a_partner',
      // Store
      storeSetting: {
        option: 'constant', // ประเภทคอมมิชชั่น
        amount: '', // จำนวนที่หัก (คำนวณตาม option)
        privilege: [], // สิทธิ์การใช้หน้าของพันธมิตร
      },
      // Driver
      driverSetting: {
        // ส่วนที่หัก
        option: 'constant', // ประเภทคอมมิชชั่น
        amount: '', // จำนวนที่หัก (คำนวณตาม option)
        privilege: [], // สิทธิ์การใช้หน้าของพันธมิตร
        deliveryTable: [
          {
            start: '', // ระยะทางเริ่มต้น
            end: '', // ระยะสิ้นสุด
            price: '', // ราคา
          },
        ], // ตารางการจัดส่ง
      },
      // Services
      serviceSetting: {
        option: 'constant', // ประเภทคอมมิชชั่น
        amount: '', // จำนวนที่หัก (คำนวณตาม option)
        privilege: [], // สิทธิ์การใช้หน้าของพันธมิตร
      },
      systemInPackages: [
        {
          systemInServicesId: null,
          status: false, // การเปิดใช้งานระบบ
        },
      ],
      required,
    }
  },
  computed: {
    systemInServicesData() {
      return store.state[STORE_MODULE_NAME].respDataSystemInService != null ? store.state[STORE_MODULE_NAME].respDataSystemInService : []
    },
    serviceAreaData() {
      return store.state[STORE_MODULE_NAME_SERVICE_AREA].respData != null ? store.state[STORE_MODULE_NAME_SERVICE_AREA].respData.data : []
    },
    OptionSystemOptions() {
      return [
        { name: this.$t('Join as a partner'.toUpperCase()), value: 'join_as_a_partner' },
        { name: this.$t('Not joining as a partner'.toUpperCase()), value: 'not_joining_as_a_partner' },
      ]
    },
    Options() {
      return [
        { name: this.$t('Constant'), value: 'constant' },
        { name: this.$t('Percent'), value: 'percent' },
      ]
    },
    OptionsPayForThePackage() {
      return [
        { name: this.$t('Package'), value: 'package' },
        { name: this.$t('Commission'), value: 'commission' },
      ]
    },
    tableColumns() {
      return [
        { key: 'start', label: this.$t('Start'), sortable: false },
        { key: 'end', label: this.$t('End'), sortable: false },
        { key: 'price', label: this.$t('Price'), sortable: false },
        { key: 'action', label: this.$t('Action'), sortable: false },
      ]
    },
    tableSystemInServices() {
      return [
        { key: 'systemId', label: this.$t('System'), sortable: false },
        { key: 'status', label: this.$t('Status'), sortable: false },
        { key: 'action', label: this.$t('Action'), sortable: false },
      ]
    },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    if (!store.hasModule(STORE_MODULE_NAME_SERVICE_AREA)) store.registerModule(STORE_MODULE_NAME_SERVICE_AREA, storeModuleServiceArea)

    if (this.$route.params.id !== 'add') {
      const obj = {
        id: this.$route.params.id,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/view`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          const respData = result.data.data
          this.selectedData(respData)
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    }
    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME_SERVICE_AREA}/get`, {
        currentPage: 0,
        pageSize: 0,
        searchQuery: '',
      })
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/getSystemInServices`, {})
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME_SERVICE_AREA)) store.unregisterModule(STORE_MODULE_NAME_SERVICE_AREA)
  },
  setup() {
    return { STORE_MODULE_NAME, STORE_MODULE_NAME_SERVICE_AREA }
  },
  methods: {
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    selectedData(data) {
      this.dataId = data._id
      this.name = data.name
      this.detail = data.detail
      this.mainDetail = data.mainDetail
      this.serviceArea = data.serviceAreaId
      this.systemOptions = data.systemOptions
      this.storeSetting = data.storeSetting
      this.driverSetting = data.driverSetting
      this.serviceSetting = data.serviceSetting
      this.payForThePackage = data.payForThePackage
      this.limitedTime = data.limitedTime
      this.repeated = data.repeated
      this.freeTialTime = data.freeTialTime
      this.limitedServices = data.limitedServices
      this.image = data.image
      this.color = data.color
      if (data.systemInPackages) {
        if (data.systemInPackages.length > 0) {
          this.systemInPackages = data.systemInPackages
        } else {
          this.systemInPackages = [{
            systemInServices: null,
            status: false, // การเปิดใช้งานระบบ
          }]
        }
      }
    },
    deleteDeliveryTable(index) {
      this.driverSetting.deliveryTable.splice(index, 1)
    },
    addDeliveryTable() {
      this.driverSetting.deliveryTable.push({
        start: '', // ระยะทางเริ่มต้น
        end: '', // ระยะสิ้นสุด
        price: '', // ราคา
      })
    },
    addSystemInServicesTable() {
      this.systemInPackages.push({
        systemInServices: null,
        status: false, // การเปิดใช้งานระบบ
      })
    },
    deleteSystemInServicesTable(index) {
      this.systemInPackages.splice(index, 1)
    },
    switchChangSystemInServices(index, status) {
      this.systemInPackages[index].status = status
    },
    validationForm() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to save')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$refs.formData.validate().then(success => {
              if (success) {
                const resourceSystemInPackages = []
                const arraySystemInPackages = []
                for (let index = 0; index < this.systemInPackages.length; index += 1) {
                  if (this.systemInPackages[index].systemInServicesId !== null) {
                    if (!resourceSystemInPackages.includes(this.systemInPackages[index].systemInServicesId)) {
                      resourceSystemInPackages.push(this.systemInPackages[index].systemInServicesId)
                      arraySystemInPackages.push({
                        systemInServicesId: this.systemInPackages[index].systemInServicesId,
                        status: this.systemInPackages[index].status,
                      })
                    }
                  }
                }
                this.systemInPackages = arraySystemInPackages

                const obj = {
                  id: this.dataId,
                  repeated: true,
                  limitedTime: this.limitedTime,
                  freeTialTime: this.freeTialTime,
                  limitedServices: this.limitedServices,
                  payForThePackage: this.payForThePackage,
                  image: this.image,
                  color: this.color,
                  name: this.name,
                  detail: this.detail,
                  mainDetail: this.mainDetail,
                  serviceArea: this.serviceArea,
                  systemOptions: this.systemOptions,
                  serviceType: this.$route.params.serviceType.replace('-', '_'),
                  // Store
                  storeSetting: this.storeSetting,
                  // Driver
                  driverSetting: this.driverSetting,
                  // Services
                  serviceSetting: this.serviceSetting,
                  systemInPackages: this.systemInPackages,
                }
                if (obj.id !== null) {
                  this.show = true

                  store
                    .dispatch(`${STORE_MODULE_NAME}/update`, obj)
                    .then(result => {
                      console.log('fetch Success : ', result)
                      this.show = false
                      this.$router.push({ path: `/hero/partner/package/${this.$route.params.serviceType}` })
                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Success'),
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: this.$t('Success'),
                        },
                      })
                    }).catch(error => {
                      console.log('fetchUsers Error : ', error)
                      this.show = false

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Error'),
                          icon: 'ErrorIcon',
                          variant: 'danger',
                          text: this.$t(error.response.data.message),
                        },
                      })
                    })
                } else {
                  this.show = true

                  store
                    .dispatch(`${STORE_MODULE_NAME}/add`, obj)
                    .then(result => {
                      console.log('fetch Success : ', result)
                      this.show = false

                      this.$router.push({ path: `/hero/partner/package/${this.$route.params.serviceType}` })

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Success'),
                          icon: 'CheckIcon',
                          variant: 'success',
                          text: this.$t('Success'),
                        },
                      })
                    }).catch(error => {
                      console.log('fetchUsers Error : ', error)
                      this.show = false

                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                          title: this.$t('Error'),
                          icon: 'ErrorIcon',
                          variant: 'danger',
                          text: this.$t(error.response.data.message),
                        },
                      })
                    })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t('Error Validator'),
                  },
                })
              }
            })
          }
        })
    },
    cancel() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to cancel')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$router.push({ path: `/hero/partner/package/${this.$route.params.serviceType}` })
          }
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
